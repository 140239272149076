import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { StorageFacade } from 'storage-store-facade/storage.facade';
import { Observable, from, throwError, combineLatest, of, EMPTY } from 'rxjs';
import { first, map, switchMap, catchError, concatMap } from 'rxjs/operators';
import { Auth } from 'app/models';
import { getDeviceInfoHeaders, DeviceInfoHeaders, getAuthHeaders } from './headers';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(private storage: StorageFacade) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest([from(this.storage.get('auth')), from(getDeviceInfoHeaders())]).pipe<
      any,
      HttpEvent<any>
    >(
      first(),
      switchMap(([auth, deviceInfoHeaders]: [Auth, DeviceInfoHeaders]) => {
        let req: HttpRequest<any>;
        if (auth) {
          const headers = auth.headers;
          const requestHeaders = request.headers.keys.length !== 0 ? request.headers : {};
          const httpHeaders = new HttpHeaders({
            ...getAuthHeaders(request, headers),
            ['refresh-access-token']: 'true',
            ...deviceInfoHeaders,
            ...requestHeaders,
          });

          req = request.clone({
            headers: httpHeaders,
          });
        } else {
          return EMPTY;
        }
        return next.handle(req).pipe(
          concatMap((event: HttpEvent<any>) =>
            event instanceof HttpResponse ? this.refreshToken(event) : of(event)
          ),
          map((event: HttpEvent<any>) => {
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            return throwError(error);
          })
        );
      })
    );
  }

  private refreshToken(response: HttpResponse<any>): Observable<HttpEvent<any>> {
    if (response.headers.get('access-token')?.trim()) {
      return from(this.storage.get('auth')).pipe(
        concatMap(({ headers, data }: Auth) => {
          return from(
            this.storage.set('auth', {
              headers: {
                ...headers,
                ['access-token']: response.headers.get('access-token'),
              },
              data,
            })
          );
        }),
        map(() => {
          return response;
        })
      );
    } else {
      return of(response);
    }
  }
}
