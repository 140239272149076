import { Device } from '@capacitor/device';
import { HttpRequest } from '@angular/common/http';
import { Headers } from 'app/models';
import { environment } from 'environments/environment';

export interface DeviceInfoHeaders {
  'device-id': string;
  'device-platform': string;
  'device-model': string;
  'device-model-manufacturer': string;
  'device-os': string;
  'device-os-version': string;
  'app-version': string;
}

export async function getDeviceInfoHeaders(): Promise<DeviceInfoHeaders> {
  let infoHeaders: DeviceInfoHeaders;
  return Device.getInfo()
    .then((d) => {
      infoHeaders = {
        ['device-id']: '',
        ['device-platform']: d.platform,
        ['device-model']: d.model,
        ['device-model-manufacturer']: d.manufacturer,
        ['device-os']: d.platform,
        ['device-os-version']: d.osVersion,
        ['app-version']: environment.version,
      };
      return Device.getId();
    })
    .then((d) => {
      infoHeaders = { ...infoHeaders, ['device-id']: d.uuid };
      return infoHeaders;
    });
}

export function getAuthHeaders(request: HttpRequest<any>, headers: Headers) {
  const requestHeaders = request.headers.keys.length !== 0 ? request.headers : {};

  return {
    ['access-token']: headers['access-token'],
    ['client']: headers.client,
    ['uid']: headers.uid,
    ['accept']: 'application/json, text/plain',
    ...requestHeaders,
  };
}
